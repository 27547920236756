@font-face {
  font-family: 'SF Compact';
  src: url("/src/fonts/sf-compact-display-medium-5864711817c30.otf"); 
  src: url("/src/fonts/sf-compact-display-medium-5864711817c30.otf") format("opentype");
  font-style: normal;
}

body {
  background: black !important;
  font-family: "SF Compact" !important;
}

.App {
  text-align: center;
}

.ant-menu-horizontal {
  background: black !important;
}

#unity-canvas {
  width: 100% !important;
  height: auto !important;
  max-height: 700px !important;
}

.home-header-div {
  background-image: url("/src/images/vbo-bg-02.png");
  height: 800px;
  background-size: contain;
  background-repeat: no-repeat;
}

.home-header-description {
  max-width: 50%;
  margin: auto;
}

.home-header-div-inside {
  padding-top: 80px;
}

@media (max-width: 800px) {
  .home-header-div-inside {
    padding-top: 20px;
  }

  .home-header-description {
    max-width: 80%;
    margin: auto;
  }

  .home-header-div {
    background-image: none;
    height: fit-content;
    background-size: contain;
    background-repeat: no-repeat;
  } 
}

.video-section {
  padding: 200px 0px 200px 0px;
  background-color: black;
}